module.exports = {
  siteTitle: 'My Islam', // <title>
  manifestName: 'WebSite',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/Islamic-Conference.png',
  pathPrefix: `/rany-elhousieny-resume/`, // This path is subpath of your hosting https://domain/portfolio
  firstName: 'MY',
  lastName: 'ISLAM',

  // social
  socialLinks: [
    {
      icon: 'fa-youtube',
      name: 'YouTube',
      url: 'https://www.youtube.com/channel/UCZDYWIWY1pMaGg4-iw3uz1w',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/company/iloveallah-net',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/ILoveAl08168349',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://www.facebook.com/pg/iloveallahalone.net/videos/',
    },
  ],
  email: 'IOnlyLoveAllahAlone@gmail.com',
  phone: '(206) 569-8844',
  address: 'Seattle, WA, USA',
};
