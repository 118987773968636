import React from 'react';

import Layout from '../components/Layout';

import Sidebar from '../components/Sidebar';
import config from '../../config';
import Classes from './index.module.css';
import happy from '../assets/images/happy.png';
import icob_happy from '../assets/images/icob_happiness.png';

import arrow_right from '../assets/images/arrow_right.png';
import arrow_left from '../assets/images/arrow_left.png';

const Current = () => (
  <Layout>
    <Sidebar showTabs="happy" />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="happy"
      >
        <div className="w-100">
          <h2 className="mb-5">
            <span className={Classes.blink}>[NEW]</span>
            <span style={{ textDecoration: 'underline' }}>
              Practical Steps to happiness خطوات عملية للسعادة{' '}
            </span>
          </h2>
          <h4 className={Classes.blink}>
            Online July 2020 - Online via -
            <a href="https://us02web.zoom.us/j/82927260711" target="blank">
              Zoom
            </a>{' '}
            - https://us02web.zoom.us/j/82927260711 Meeting ID: 829 2726 0711{' '}
            <a href={icob_happy}>Every Friday at 9:30PM PST</a>
            -- Send me an email at{' '}
            <a href={`mailto:${config.email}`}>{config.email}</a> to give you
            the Zoom Link
          </h4>
          <p style={{ fontSize: '30px', color: 'blue' }}>
            {' '}
            This is a practical workshop for those looking for happiness. We
            will have an item to practice every week to reach a higher status
            for our hearts in shaa Allah. This series is based on multiple
            books, lectures, and experiences (Refer to the{' '}
            <a href="#references">References </a> section)
          </p>

          <div>
            <span className={Classes.blink_infinite}>
              <img
                className="img-fluid img-profile mx-auto mb-2,"
                src={arrow_right}
                alt=""
                height="100"
                width="100"
              />
            </span>
            {'   '}
            <span
              className={Classes.blink}
              style={{ fontSize: '50px', fontWeight: 'bolder' }}
            >
              <a
                href="https://iloveallah.net/static/2020Jul24_plan.pdf"
                target="blank"
              >
                | 8/25/2020 : This week's plan |
              </a>
            </span>
          </div>
          <div>
            <span className={Classes.blink_infinite}>
              <img
                className="img-fluid img-profile mx-auto mb-2,"
                src={arrow_right}
                alt=""
                height="20"
                width="20"
              />
            </span>
            {'   '}
            <span
              className={Classes.blink}
              style={{ fontSize: '20px', textAlign: 'center' }}
            >
              <a
                href="https://iloveallah.net/static/Zikr_table2.pdf"
                target="blank"
              >
                | 8/25/2020 : List of Zikr for this week |
              </a>
            </span>
          </div>

          <ul className={Classes.FocusLinked}>
            <li>
              <a
                href="https://www.youtube.com/playlist?list=PLNT8BTFvQYHLWc7EkqE0OYo1sRCuQwYzv"
                target="blank"
              >
                Practical Steps to happiness خطوات عملية للسعادة (YouTube
                PlayList)
              </a>
            </li>
            <p />
            <ul>
              <li>
                <h4>
                  <a href="#step4">
                    4. Step 4: Perfection - Ehsan - الاحسان{' '}
                    <span className={Classes.blink}>[8/25/2020]</span>
                  </a>
                  <span className={Classes.blink_infinite}>
                    <img
                      className="img-fluid img-profile mx-auto mb-2,"
                      src={arrow_left}
                      alt=""
                      height="30"
                      width="30"
                    />
                  </span>
                  <span>We are here</span>
                </h4>
              </li>
              <li>
                <>
                  <a href="#step3">
                    3. Third Step: Work For One <span>[8/16/2020]</span>
                  </a>
                </>
              </li>
              <li>
                <a href="#step2">
                  2. Second Step: Benefit from Opportunities{' '}
                  <span>[7/18/2020]</span>
                </a>
              </li>
              <li>
                <a href="#step1">
                  1. First Step: Ask Allah For Help <span>[7/11/2020]</span>
                </a>
              </li>
              <li>
                <>
                  <a href="#introduction">0. Introduction - 7/3/2020</a>
                </>
              </li>
            </ul>
          </ul>
          <h4>
            <a href="#references">References </a>
          </h4>
        </div>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="introduction"
      >
        <h4>0. Introduction</h4>
        <ul>
          <li>
            <a href="https://youtu.be/5ffrMcPFgTQ" target="blank">
              YouTube Video (Please share)
            </a>
          </li>
          <li>
            <a
              href="https://iloveallah.net/static/1_Practical_Steps_to_Happiness_Introduction_Final.pdf"
              target="blank"
            >
              Slides in PDF format to downlad or read on browser
            </a>
          </li>
          <li>
            <a
              href="https://iloveallah.net/static/1_Practical_Steps_to_Happiness_Introduction.pptx"
              target="blank"
            >
              Slides in PowerPoint format with voice to downlad
            </a>
          </li>
          <li>
            <a
              href="https://iloveallah.net/static/happiness_1.mp4"
              target="blank"
            >
              Video in .mp4 format to downlad or watch on browser
            </a>
          </li>
          <li>
            <a
              href="#happy"
              style={{ color: 'red', fontSize: '30px', fontWeight: 'bolder' }}
            >
              Back to Top
            </a>
          </li>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="step1"
      >
        <h4>1. First Step: Ask Allah For Help (Duaa and Monajah) : </h4>{' '}
        <ul>
          <li>
            <a href="https://youtu.be/btviYS_NypE" target="blank">
              YouTube Video (Please share)
            </a>
          </li>
          <li>
            <a href="https://iloveallah.net/static/step1.pdf" target="blank">
              Slides in PDF format to downlad or read on browser
            </a>
          </li>
          <li>
            <a href="https://iloveallah.net/static/step1.pptx" target="blank">
              Slides in PowerPoint format with voice to downlad
            </a>
          </li>
          <li>
            <a href="https://iloveallah.net/static/step1.mp4" target="blank">
              Video in .mp4 format to downlad or watch on browser
            </a>
          </li>
          <li>
            <a
              href="https://iloveallah.net/static/jul11plan.pdf"
              target="blank"
            >
              Suggested and example action plan for the week
            </a>
          </li>
        </ul>
      </section>

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="step2"
      >
        <h4>2. Second Step: Benefit from Opportunities : </h4>{' '}
        <ul>
          <li>
            <a href="https://youtu.be/w2Hh4G-Oi5s" target="blank">
              YouTube Video (Please share)
            </a>
          </li>
          <li>
            <a href="https://iloveallah.net/static/step2.pdf" target="blank">
              Slides in PDF format to downlad or read on browser
            </a>
          </li>
          <li>
            <a href="https://iloveallah.net/static/step2.pptx" target="blank">
              Slides in PowerPoint format with voice to downlad
            </a>
          </li>
          <li>
            <a href="https://iloveallah.net/static/step2.mp4" target="blank">
              Video in .mp4 format to downlad or watch on browser
            </a>
          </li>
          <li>
            <a
              href="https://iloveallah.net/static/2020Jul24_plan.pdf"
              target="blank"
            >
              Suggested and example action plan for the week
            </a>
          </li>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="step3"
      >
        <h4>3. Third Step: Work for One : </h4>{' '}
        <ul>
          <li>
            <a href="https://youtu.be/YT1EXbGnJy8" target="blank">
              YouTube Video (Please share)
            </a>
          </li>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="step4"
      >
        <h4>4. Step 4: Perfection - Ehsan - الاحسان : </h4>{' '}
        <ul>
          <li>
            <a href="https://youtu.be/txyYXI-xfPE" target="blank">
              YouTube Video (Please share)
            </a>
          </li>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="worry"
      >
        <ul className={Classes.FocusLinked}>
          <li>
            <h4>Medications for worries, stress, panic, and anxiety</h4>
          </li>
          <ol className={Classes.FocusLinked}>
            <li>
              <h5>Introduction</h5>
            </li>
            <ul>
              <li>
                <a
                  href="https://iloveallah.net/static/Survive.pdf"
                  target="blank"
                >
                  Slides to read and download in PDF Format
                </a>
              </li>
              <li>
                <a href="https://youtu.be/BbRmfxtHUyM" target="blank">
                  YouTube Video [please share]
                </a>
              </li>
              <li>
                <a
                  href="https://iloveallah.net/static/survive.mp4"
                  target="blank"
                >
                  Video in .mp4 format to downlad or watch on browser
                </a>
              </li>
            </ul>
          </ol>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="references"
      >
        <h4>References:</h4>
        <ul>
          <li>
            <a href="https://youtu.be/8LwXP7HrZlg">
              Are you searching for Happiness?
            </a>
          </li>
          <li>
            <a href="/duaa">Duaa and Supplication to Allah الدعاء</a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/playlist?list=PLNT8BTFvQYHLmHSeXABFEYS-hZ9IwGdWI"
              target="blank"
            >
              [English - YouTube PlayList] Practicing Madarej Assalekeen - تطبيق
              كتاب مدارج السالكين -
            </a>
          </li>
          <li>
            <a href="/hearts" target="blank">
              [English] Purifying our Hearts اصلاح القلوب
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/playlist?list=PLEB7B19DAC6DC2A16"
              target="blank"
            >
              [Arabic Only - YouTube] Amr Khaled Series "A Journey to happiness"
              - رحلة الي السعادة د.عمرو خالد
            </a>
          </li>
          <li>
            <a
              href="https://www.amazon.com/s?k=9789953874500&i=stripbooks&linkCode=qs&tag=x_gr_w_bb_sin-20&ref=x_gr_w_bb_sin"
              target="blank"
            >
              [Arabic Only - Book] Amr Khaled Series "A Journey to happiness" -
              رحلة الي السعادة د.عمرو خالد
            </a>
          </li>
          <li>
            <a
              href="https://iloveallah.net/static/Madarij-as-Salikeen-Imam-Ibn-Qayim-Al-Jawziyah-ENG.pdf"
              target="blank"
            >
              [English] Book of Madarij Assalekeen - كتاب مدارج السالكين
            </a>
          </li>
          <li>
            <a
              href="https://ar.wikisource.org/wiki/%D9%85%D8%AF%D8%A7%D8%B1%D8%AC_%D8%A7%D9%84%D8%B3%D8%A7%D9%84%D9%83%D9%8A%D9%86"
              target="blank"
            >
              [Arabic] Book of Madarij Assalekeen - كتاب مدارج السالكين ابن قيم
              الجوزية
            </a>
          </li>
          <li>
            <a
              href="https://www.amazon.com/Ibn-Qayyim-al-Jawziyya-Invocation-God/dp/B00NPO7BSC"
              target="blank"
            >
              [English] Book of Al-Wabil al-Sayyib [Ibn al Qaiem] - الوابل الصيب
              من الكلم الطيب
            </a>
          </li>
          <li>
            <a
              href="https://ar.wikisource.org/wiki/%D9%83%D8%AA%D8%A7%D8%A8_%D8%A7%D9%84%D9%88%D8%A7%D8%A8%D9%84_%D8%A7%D9%84%D8%B5%D9%8A%D8%A8"
              target="blank"
            >
              [Arabic] الوابل الصيب من الكلم الطيب ابن قيم الجوزية
            </a>
          </li>
          <li>
            <a
              href="https://www.amazon.com/%E2%80%AB%D9%85%D8%AF%D8%A7%D8%B1%D8%AC-%D8%A7%D9%84%D8%B3%D8%A7%D9%84%D9%83%D9%8A%D9%86-%D9%85%D9%86%D8%A7%D8%B2%D9%84-%D9%86%D8%B3%D8%AA%D8%B9%D9%8A%D9%86-Arabic-ebook/dp/B07771R9B5"
              target="blank"
            >
              [Arabic] كتاب مدارج السالكين ابن قيم الجوزية
            </a>
          </li>

          <li>
            <a
              href="https://ar.islamway.net/collection/1807/%D8%B4%D8%B1%D8%AD-%D9%83%D8%AA%D8%A7%D8%A8-%D9%85%D8%AF%D8%A7%D8%B1%D8%AC-%D8%A7%D9%84%D8%B3%D8%A7%D9%84%D9%83%D9%8A%D9%86"
              target="blank"
            >
              [Arabic Only - Audio] Madarej Assalekeen - شرح كتاب مدارج السالكين
              - محمد حسين يعقوب - طريق الإسلام
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/playlist?list=PL334BFE277C8B938B"
              target="blank"
            >
              [Arabic Only - Youtube PlayList] الجنة في بيوتنا - عمرو خالد
            </a>
          </li>

          <li>
            <a href="https://sunnah.com/" target="blank">
              https://sunnah.com/
            </a>
          </li>
          <li>
            <a href="https://www.dorar.net/hadith" target="blank">
              https://www.dorar.net/hadith
            </a>
          </li>
          <li>
            <a
              href="https://www.islamicity.org/quransearch/?AspxAutoDetectCookieSupport=1"
              target="blank"
            >
              Quran SmartSearch
            </a>
          </li>
        </ul>
      </section>
      <img
        className="img-fluid img-profile mx-auto mb-2"
        src={icob_happy}
        alt="PSM"
      />
      <img
        className="img-fluid img-profile mx-auto mb-2"
        src={happy}
        alt="PSM"
      />
    </div>
  </Layout>
);

export default Current;
